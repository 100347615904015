
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { Customer, Program } from '@/models'
import { Form } from 'element-ui'
import { useUserStore } from '@/stores/user'
import { getEmailConfirmationInfo, approvementCheck } from '@/api/customers'

@Component({
  name: 'DialogCustomer'
})
export default class extends Vue {
  @Prop({ required: false, default: false }) private manualVisible!: boolean
  @Prop({ required: true }) private customer!: Customer | null

  userStore: any = useUserStore()

  visible = false
  dialogStatus = 'create'

  tempCustomer: Customer = new Customer({ program: { id: null } })
  emailConfirmation: any | null = null
  loadingCreate = false

  programs: Program[] = []
  programsLoading = false

  loadingCheckEmail = false

  situations: any = [
    { label: this.$t('customerDialog.retired'), value: true },
    { label: this.$t('customerDialog.employee'), value: false }
  ]

  rules: any = {
    firstname: [
      { required: true, message: this.$t('customerDialog.rules.firstname'), trigger: 'blur' }
    ],
    lastname: [
      { required: true, message: this.$t('customerDialog.rules.lastname'), trigger: 'blur' }
    ],
    email: [
      { required: true, message: this.$t('customerDialog.rules.email'), trigger: 'blur' }
    ],
    'program.id': [
      { required: true, message: this.$t('customerDialog.rules.programId'), trigger: 'blur' }
    ]
  }

  @Watch('visible', { immediate: true })
  private onVisibleChange(visible: boolean) {
    if (visible) !this.customer ? this.handleCreate() : this.handleUpdate()
    else this.emailConfirmation = null
  }

  @Watch('manualVisible')
  setVisibility(visible: boolean): void {
    this.visible = visible
  }

  get isCaretakerIEG(): boolean {
    return this.userStore.isCaretakerIEG
  }

  created(): void {
    this.getPrograms()
  }

  private async getPrograms() {
    this.programsLoading = true

    const { data } = await Program
      .page(1)
      .per(999)
      .stats({ total: 'count' })
      .all()

    this.programs.push(...data)

    this.programsLoading = false
  }

  async createOrUpdate() {
    (this.$refs.customerForm as Form).validate(async(valid) => {
      this.loadingCreate = true
      if (valid) {
        const with_ = this.dialogStatus === 'create' ? '' : 'program.id'

        try {
          if (this.dialogStatus === 'create') {
            await this.tempCustomer.save({ with: with_ })
          } else {
            if (this.tempCustomer.registrationFailureReason === this.customer!.registrationFailureReason) {
              await this.tempCustomer.save({ with: with_ })
            }
          }
          const programDetails = this.findAndSetProgramInfo(this.tempCustomer.program.id)
          if (programDetails) this.tempCustomer.program = programDetails

          if (this.dialogStatus === 'create') {
            this.$emit('created', this.tempCustomer.dup())
            this.$notify({
              title: this.$t('customerDialog.notifications.success.title') as string,
              message: this.$t('customerDialog.notifications.success.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          } else {
            this.$emit('updated', this.tempCustomer.dup())
            this.$notify({
              title: this.$t('customerDialog.notifications.update.title') as string,
              message: this.$t('customerDialog.notifications.update.subtitle') as string,
              type: 'success',
              duration: 2000
            })
          }

          this.tempCustomer = new Customer({ program: { id: null } })
          this.$emit('close')
          this.visible = false
        } catch (err) {
          console.log(err)
          this.$notify({
            title: this.$t('customerDialog.notifications.error.title') as string,
            message: this.$t('customerDialog.notifications.error.subtitle') as string,
            type: 'error',
            duration: 2000
          })
          this.loadingCreate = false
        }
      }
      this.loadingCreate = false
    })
  }

  findAndSetProgramInfo(programId: string | undefined): Program | null {
    if (!programId) return null

    const program = this.programs.find((program) => program.id === programId)
    if (program) return program

    return null
  }

  handleCreate() {
    this.dialogStatus = 'create'
    this.tempCustomer = new Customer({ program: { id: null }, isRetired: this.isCaretakerIEG ? true : undefined })

    this.$nextTick(() => {
      (this.$refs.customerForm as Form).clearValidate()
    })
  }

  async handleUpdate() {
    this.dialogStatus = 'update'
    this.tempCustomer = this.customer!.dup()

    if (this.tempCustomer.registrationStatus !== 'verified')
      this.emailConfirmation = await getEmailConfirmationInfo(this.tempCustomer.id)

    const programDetails = this.findAndSetProgramInfo(this.tempCustomer.program.id)
    if (programDetails) this.tempCustomer.program = programDetails

    this.$nextTick(() => {
      (this.$refs.customerForm as Form).clearValidate()
    })
  }

  async approvementCheck(): Promise<void> {
    this.loadingCheckEmail = true
    try {
      const data: any = await approvementCheck(this.tempCustomer.id)
      if (data) {
        this.$emit('getCustomer', this.tempCustomer.id)
        this.tempCustomer.registrationStatus = data.state
        this.tempCustomer.registrationFailureReason = data.reason

        this.$notify({
          title: this.$t('customerDialog.notifications.checkEmailSuccess.title') as string,
          message: `${this.$t('customerDialog.notifications.checkEmailSuccess.subtitle')} ${this.$t(`customers.card.popoverFailure.${this.tempCustomer.registrationFailureReason}`)}`,
          type: 'success',
          duration: 2000
        })
      }
    } catch (error) {
      this.$notify({
        title: this.$t('customerDialog.notifications.checkEmailError.title') as string,
        message: this.$t('customerDialog.notifications.checkEmailError.subtitle') as string,
        type: 'error',
        duration: 2000
      })
    }

    this.loadingCheckEmail = false
  }
}
